import axios from "axios"
import http from "http"
import util from "./util"
import _ from "lodash"

// TODO utilize this in the findByTerm(s) functions. We could check here first before going to the server.
const keywords = []

/**
 *
 * @param {array} keywordObjects a collection of objects containing a keywords property ( containing keyword objects, not strings)
 */
export const getKeywordsForSave = (keywordObjects, keywordsFields) => {
  if (!keywordsFields) {
    keywordsFields = ["keywords", "collectionKeywords"]
  }
  var keywordsStrings = []
  _.map(keywordObjects, keywordObj => {
    _.forEach(keywordsFields, keywordsField => {
      if (keywordObj[keywordsField] && keywordObj[keywordsField].length > 0) {
        keywordsStrings.push(
          keywordObj[keywordsField].map(k => k.value).join(",")
        )
      }
    })
  })
  return keywordsStrings.join(",").trim()
}

export const getKeywords = async => {
  return new Promise(function(resolve, reject) {
    axios.get("/keywords?_limit=-1").then(
      response => {
        console.log("keywords queried successfully", response)
        resolve(util.fixUrls(response.data))
        // resolve(response.data)
      },
      error => {
        console.log("getKeywordss ERROR: keywords not found", error)
        reject(error)
      }
    )
  })
}
export const createKeywords = async keywords => {
  return new Promise(function(resolve, reject) {
    axios.post("/keywords", keywords).then(
      response => {
        console.log("Keywords created successfully", response)

        var updatedKeywords = response.data
        updatedKeywords.canEdit = true
        updatedKeywords.canDelete = true

        resolve(util.fixUrls(updatedKeywords))
      },
      error => {
        console.log("createKeywords error:", error)
        reject(error)
      }
    )
  })
}

export const updateKeywords = async (id, keywords) => {
  console.log("updateKeywords  id", id)
  return new Promise(function(resolve, reject) {
    axios.put("/keywords/" + id, keywords).then(
      response => {
        console.log("Keywords " + id + " updated successfully", response)
        // response.data is the updated story object
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("updateKeywords error:", error)
        reject(error)
      }
    )
  })
}

export const deleteKeywords = async id => {
  return new Promise(function(resolve, reject) {
    axios.delete("/keywords/delete/" + id).then(
      response => {
        console.log("Keywords " + id + " deleted successfully", response)
        // pass back the deleted keyword?
        resolve(response)
      },
      error => {
        console.log("deleteKeywords error:", error)
        reject(error)
      }
    )
  })
}

export const parseKeywordsForAutoComplete = keywords => {
  const parsed = _.map(keywords, keyword => {
    return {
      value: keyword.term,
      source: {
        _id: keyword._id,
      },
    }
  })
  return parsed
}

export const findByTerm = async term => {
  return new Promise(function(resolve, reject) {
    axios.get("/keywords/findByTerm/" + term).then(
      response => {
        const keyword = response.data.keyword
        if (keyword) {
          resolve(keyword)
        } else {
          console.log("findByTerm ERROR: No keyword found")
          resolve(null)
        }
      },
      function(error) {
        console.log("findByTerm ERROR: ".error)
        reject(error)
      }
    )
  })
}

export const findByTerms = async terms => {
  if (terms && terms.length > 0) {
    try {
      const response = await axios.get("/keywords/findByTerms/" + terms)
      const keywords = response.data.keywords
      if (keywords && keywords.length > 0) {
        return keywords
      } else {
        console.log("findByTerms ERROR: No keywords found")
        return []
      }
    } catch (error) {
      console.log("findByTerms ERROR: ".error)
      throw error
    }
  } else {
    return []
  }
}

export const moveKeyword = async updatedObj => {
  console.log("updatedOBJ", updatedObj)
  return new Promise(function(resolve, reject) {
    axios.post("/keywords/moveKeywords", updatedObj).then(
      response => {
        console.log("Keywords updated successfully", response)
        // response.data is the updated story object
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("moveKeywords error:", error)
        reject(error)
      }
    )
  })
}


export const moveKeywords = async UpdatedObj => {
  console.log("UpdatedOBJ", UpdatedObj)
  return new Promise(function(resolve, reject) {
    axios.post("/keywords/moveChildrenKeywords", UpdatedObj).then(
      response => {
        console.log("Keywords updated successfully", response)
        // response.data is the updated story object
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("moveChildrenKeywords error:", error)
        reject(error)
      }
    )
  })
}

export const renameKeyword = async (keyword) => {
  return new Promise(function(resolve, reject) {
    axios.post("/keywords/renameKeyword", keyword).then(
      response => {
        console.log("Keywords renamed successfully", response)
        // response.data is the updated story object
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("renameKeyword error:", error)
        reject(error)
      }
    )
  })
}

export default {
  getKeywordsForSave,
  parseKeywordsForAutoComplete,
  findByTerm,
  findByTerms,
  getKeywords,
  createKeywords,
  deleteKeywords,
  updateKeywords,
  moveKeyword,
  renameKeyword,
  moveKeywords
}
