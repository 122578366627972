import axios from "axios"
import _ from "lodash"
import util from "./util"

// export const thumbnailUrl = media => {
//   return `${axios.defaults.baseURL}/thumbnail/${media.id}/${media.thumbnailName}`
// }

// export const webViewUrl = media => {
//   return `${axios.defaults.baseURL}/webView/${media.id}/${media.webViewName}`
// }

// export const downloadUrl = media => {
//   return `${axios.defaults.baseURL}/download/${media.id}/${media.thumbnailName}`
// }

// const baseUrl = `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/`

// const baseUrl = process.env.GATSBY_FEDIA_DMS_PORT && process.env.GATSBY_FEDIA_DMS_PORT !== '80' && process.env.GATSBY_FEDIA_DMS_PORT !== 80 ? 
//   `https://${process.env.GATSBY_FEDIA_DMS_HOST}:${process.env.GATSBY_FEDIA_DMS_PORT}/` :
//   `http://${process.env.GATSBY_FEDIA_DMS_HOST}/`;

//   const fixUrls = media => {
//     return {
//       ...media,
//       webViewUrl: media.webViewUrl ? `${baseUrl}${media.webViewUrl.replace('http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/','')}` : media.webViewUrl,
//       thumbnailUrl: media.thumbnailUrl ? `${baseUrl}${media.thumbnailUrl.replace('http://AppSt-Fedia-PQ7U6WD4330R-317694051.ca-central-1.elb.amazonaws.com/','')}` : media.thumbnailUrl
//     };
//   }

/**
 *
 * @param {*} id
 */
export const getMedia = async id => {
  return new Promise(function(resolve, reject) {
    axios.get("/media/" + id).then(
      response => {
        console.log("media queried successfully", response)
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("getMedia ERROR: media not found", error)
        reject(error)
      }
    )
  })
}

/**
 *
 * @param {*} keyword
 */
export const getMediaByKeyword = async keyword => {
  return new Promise(function(resolve, reject) {
    axios.get("/media/findByKeyword/" + keyword).then(
      response => {
        console.log("media queried successfully", response)
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("getMedia ERROR: media not found", error)
        reject(error)
      }
    )
  })
}

/**
 *
 * @param {*} media an array of medias or a single media object to be created
 */
export const createMedia = async media => {
  return new Promise(function(resolve, reject) {
    axios.post("/media", media).then(
      response => {
        console.log("media created successfully", response)
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("createMedia error:", error)
        reject(error)
      }
    )
  })
}

export const updateMedia = async media => {
  return new Promise(function(resolve, reject) {
    var id = media._id ? media._id : media.id
    axios.put("/media/" + id, media).then(
      response => {
        console.log("media " + id + " updated successfully", response)
        // response.data is the updated media object
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("updateMedia error:", error)
        reject(error)
      }
    )
  })
}

export const deleteMedia = async media => {
  var id = _.isString(media) ? media : media._id ? media._id : media.id
  return new Promise(function(resolve, reject) {
    axios.delete("/media/" + id).then(
      response => {
        console.log("media " + media._id + " deleted successfully", response)
        // pass back the deleted media?
        resolve(media)
      },
      error => {
        console.log("deleteMedia error:", error)
        reject(error)
      }
    )
  })
}

export const getAllMedia = asyn => {
  return new Promise(function(resolve, reject) {
    axios.get("/media").then(
      response => {
        console.log("media queried successfully", response)
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("getMedia ERROR: media not found", error)
        reject(error)
      }
    )
  })
}

export default {
  getMedia,
  createMedia,
  updateMedia,
  deleteMedia,
  getAllMedia
}
